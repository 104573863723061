import { Box, Text, Link } from "@chakra-ui/core";
import React from "react";
import {
  FaBolt,
  FaBook,
  FaCubes,
  FaExpandArrowsAlt,
  FaMobile,
  FaServer,
} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Columns from "../components/columns";
import Container from "../components/container";
import Layout from "../components/layout";
import Pricing from "../components/Pricing/Pricing";
import PricingButton from "../components/Pricing/PricingButton";
import SEO from "../components/seo";
import styles from "./crm-cennik.module.css";
import FeatureListItem from "../components/Pricing/FeatureListItem";
import FeatureList from "../components/Pricing/FeatureList";
import Img from "gatsby-image";
import MultiStepFormButton from "../components/MultiStepForm/MultiStepFormButton";
import { graphql, Link as GatsbyLink } from "gatsby";
import Hero from "../components/HeroBanner";

export const query = graphql`
  query {
    heroBg: file(relativePath: { eq: "system-dla-agencji-reklamowej.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const CennikZakup = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Cennik - Zakup systemu"
        description="Zakup systemu dla agencji reklamowej i marketingowej ✅ Cennik • Zakup • Integracje z ERP • Zapytaj o bezpłatne demo!"
      />
      <Hero heroImage={data.heroBg} justifyContent="center">
        <h1>Zakup systemu dla agencji reklamowej i marketingowej</h1>
        <h2>Wybierz najlepszy pakiet dla Twojej firmy</h2>
      </Hero>
      <Container>
        <br />
        <Pricing
          disablePriceChoice
          yearDiscount="-10%"
          discountColor="red.400"
          specialColors={[
            "hsl(214, 20%, 69%)",
            " hsl(216, 15%, 52%)",
            "  hsl(218, 17%, 35%)",
          ]}
          allFunctions={allFunctions}
          pricingData={pricingData}
          showFeaturesTables
        ></Pricing>
      </Container>
      <Container>
        <div className={styles.benefits2}>
          <h3
            style={{
              paddingTop: 20,
              color: "hsl(0, 0%, 0%)",
              textAlign: "center",
            }}
          >
            Integracje z ERP
          </h3>
          <p>
            <strong>
              System dla agencji reklamowej i marketingowej możemy zintegrować z
              popularnymi na rynku programami handlowo-magazynowymi:
            </strong>{" "}
            <br /> Subiekt GT, Subiekt Nexo, Sage Symfonia Handel, Sage Symfonia
            ERP, Comarch Optima, Comarch CDN XL, Asseco Wapro Wf-Mag, Navireo,
            Enova. Na liście nie ma Twojego programu handlowo-magazynowego?
            Napisz do nas i porozmawiajmy o możliwościach.
          </p>
        </div>
      </Container>
      <Container>
        <ReactTooltip type="info" place="right" effect="solid" />
        <div className={styles.benefits}>
          <h3 className={styles.benefits__header}>
            Preferujesz jednorazowy zakup systemu?
          </h3>
          <br />
          <Box textAlign="center">
            <PricingButton mx="auto" to="/cennik-wynajem/">
              Sprawdź cennik wynajmu
            </PricingButton>
          </Box>
        </div>
        <div className={styles.benefits}>
          <h3 className={styles.benefits__header}>
            Dedykowane rozwiązanie - Aura Business
          </h3>
          <br />
          <p>
            Rozwiązanie{" "}
            <strong>
              <a href="https://aurabusiness.pl/">Aura Business</a>
            </strong>{" "}
            to oprogramowanie przeznaczone dla organizacji, które oczekują
            dedykowanych rozwiązań biznesowych.
          </p>
          <p>
            Elastyczne i komplementarne dla ERP narzędzia obejmują m.in.
            wsparcie sprzedaży, marketingu i realizacji usług. Podczas wdrożenia
            uwzględniane są indywidualne wymagania, które wynikają z przyjętej
            przez firmę strategii rozwoju. Chcesz dowiedzieć się więcej?
          </p>
          <br />
          <Box textAlign="center">
            <PricingButton mx="auto" to="/kontakt">
              Skontaktuj się z nami
            </PricingButton>
          </Box>
        </div>
      </Container>
    </Layout>
  );
};

const allFunctions = [
  {
    key: "separator",
    name: [<strong>Bezpieczeństwo i wsparcie</strong>],
  },
  {
    key: "rwd",
    name: "Technologia RWD",
  },
  {
    key: "aktualizacje",
    name: "Bezpłatne i częste aktualizacje",
  },
  {
    key: "braklimitow",
    name:
      "Brak limitów kontrahentów, szans sprzedaży, produktów, dokumentów handlowych",
  },
  {
    key: "wsparcie",
    name: "Bezpłatne standardowe wsparcie",
  },
  {
    key: "separator",
    name: [<strong>Wsparcie sprzedaży</strong>],
  },
  {
    key: "niestandardowe",
    name: "Zarządzanie szansami sprzedaży",
  },
  {
    key: "wiele",
    name: "Obsługa wielu różnych kampanii sprzedaży",
  },
  {
    key: "wlasnepola",
    name: "Własne pola w szansie sprzedaży",
  },
  {
    key: "cele",
    name: "Definiowanie celów na danym etapie szansy",
  },
  {
    key: "wiazanieszans",
    name: "Wiązanie zadań, dokumentów i zleceń z szansą sprzedaży",
  },
  {
    key: "wymagalnosc",
    name: "Możliwość ustawienia wymagalności uzupełniania pól",
  },
  {
    key: "utrata",
    name: "Raport z przyczynami utraty szans sprzedaży",
  },
  {
    key: "gardla",
    name: "Raport z konwersją lejka sprzedaży i wąskimi gardłami w kampanii",
  },
  {
    key: "lejek",
    name: "Raport lejka sprzedaży w ujęciu wartościowym i ilościowym",
  },
  {
    key: "prognoza",
    name: "Porównanie prognozy sprzedaży z wygranymi szansami",
  },
  {
    key: "aktywnosc",
    name: "Raport z aktywności pracowników",
  },
  {
    key: "raportofert",
    name: "Raportowanie ofertowania, zamówień i sprzedaży",
  },
  {
    key: "ofertowanie",
    name: "Spójny proces ofertowania",
  },
  {
    key: "zamowienia",
    name: "Szybkie tworzenie zamówień z oferty",
  },
  {
    key: "fakturowanie",
    name: "Fakturowanie i płatności lub integracja z ERP",
  },
  {
    key: "szablony",
    name: "Własne szablony dokumentów (ofert, zamówień i faktur)",
  },
  {
    key: "separator",
    name: [<strong>Wsparcie realizacji</strong>],
  },
  {
    key: "zlecenia",
    name: "Zarządzanie projektami i zleceniami",
  },
  {
    key: "kanban",
    name: "Wizualizacja przepływu zleceń na widoku kanban",
  },
  {
    key: "polazlecenie",
    name: "Dodawanie własnych pól do zlecenia",
  },
  {
    key: "typy",
    name: "Własne typy zleceń",
  },
  {
    key: "kalendarzzlecen",
    name: "Kalendarz zleceń",
  },
  {
    key: "raportzlecen",
    name: "Automatyczne raporty do zleceń np. pracochłonność zleceń",
  },
  {
    key: "separator",
    name: [<strong>Wsparcie dla firmy</strong>],
  },
  {
    key: "ofertaproduktowa",
    name: "Aktualna baza produktów i usług (ceny i stany magazynowe)",
  },
  {
    key: "bazaklientow",
    name: "Uporządkowana baza kontrahentów",
  },
  {
    key: "rabaty",
    name: "Indywidualne ceny i rabaty dla kontrahentów",
  },
  {
    key: "umowy",
    name: "Możliwość załączania umów do karty kontrahenta",
  },
  {
    key: "segmentowanie",
    name: "Własne atrybuty kontrahentów",
  },
  {
    key: "opiekun",
    name: "Przypisywanie wielu opiekunów do kontrahenta",
  },
  {
    key: "wyszukiwanie",
    name: "Zaawansowana wyszukiwarka osób i firm",
  },
  {
    key: "uprawnienia",
    name: "Zarządzanie zaawansowanymi uprawnieniami",
  },
  {
    key: "role",
    name: "Wiele ról pracowników",
  },
  {
    key: "duplikaty",
    name: "Możliwość blokowania duplikatów",
  },
  {
    key: "kalendarze",
    name: "Współdzielone kalendarze",
  },

  {
    key: "zadanianadzis",
    name: "Lista zadań na dziś",
  },
  {
    key: "zdarzenia",
    name: "Monitorowanie zdarzeń pracowników",
  },
  {
    key: "poczta",
    name: "Skrzynka pocztowa (IMAP)",
  },
  {
    key: "panelklienta",
    name: "Panel klienta",
  },
  {
    key: "sms",
    name: "Powiadomienia SMS i e-mail",
  },
  {
    key: "gus",
    name: "Pobieranie danych z GUS",
  },
  {
    key: "separator",
    name: [<strong>Rozwiązanie dedykowane</strong>],
  },
  {
    key: "procesybiznesowe",
    name: "Możliwość pełnego odwzorowania procesów biznesowych",
  },
  {
    key: "aplikacja",
    name: "Aplikacja mobilna",
  },
];

const pricingData = {
  standard: {
    name: "Standard",
    price: { onetime: "936 PLN" },
    button: (
      <MultiStepFormButton
        buttonProps={{
          mb: 4,
          boxShadow: "sm",
          w: "full",
          variantColor: "white",
          background: "white",
          color: "gray.900",
          fontWeight: "bold",
          fontSize: "sm",
        }}
      ></MultiStepFormButton>
    ),
    isRecommended: false,
    special: 0,
    bestFeatures: [
      <>
        <FeatureList>
          <FeatureListItem>
            System w technologii RWD - dopasowany do ekrany laptopa, tabletu
            i&nbsp;telefonu{" "}
          </FeatureListItem>
          ,
          <FeatureListItem>
            Planowanie zadań (współdzielone kalendarze)
          </FeatureListItem>
          ,
          <FeatureListItem>
            Baza wiedzy o kontrahentach i&nbsp;własne atrybuty
          </FeatureListItem>
          ,
          <FeatureListItem>
            Zarządzanie szansami sprzedaży (wiele kampanii)
          </FeatureListItem>
          ,<FeatureListItem>Automatyczne raporty sprzedażowe</FeatureListItem>,
          <FeatureListItem>Baza produktów i usług</FeatureListItem>,
          <FeatureListItem>
            Wyświetlanie zadań w kalendarzu i na telefonie (CalDAV)
          </FeatureListItem>
          ,
          <FeatureListItem>
            Eksport i import osób kontaktowych oraz firm
          </FeatureListItem>
          ,<FeatureListItem>Wbudowany komunikator</FeatureListItem>,
        </FeatureList>
      </>,
    ],
    features: [
      "rwd",
      "aktualizacje",
      "braklimitow",
      "wsparcie",
      "dedykowane",
      "niestandardowe",
      "wiele",
      "wlasnepola",
      "cele",
      "wiazanieszans",
      "wymagalnosc",
      "utrata",
      "gardla",
      "lejek",
      "prognoza",
      "aktywnosc",
      "ofertaproduktowa",
      "bazaklientow",
      "rabaty",
      "umowy",
      "segmentowanie",
      "opiekun",
      "wyszukiwanie",
      "uprawnienia",
      "role",
      "duplikaty",
      "kalendarze",
      "zadanianadzis",
      "zdarzenia",
    ],
  },
  pro: {
    name: "Pro",
    price: { onetime: "1560 PLN" },
    button: (
      <MultiStepFormButton
        buttonProps={{
          mb: 4,
          boxShadow: "sm",
          w: "full",
          variantColor: "white",
          background: "white",
          color: "gray.900",
          fontWeight: "bold",
          fontSize: "sm",
        }}
      ></MultiStepFormButton>
    ),
    isRecommended: true,
    special: 1,
    bestFeatures: [
      <>
        <Text color="white" mt={0}>
          Funkcje Standard oraz:{" "}
        </Text>
        <FeatureList>
          <FeatureListItem>Zarządzanie projektami i zleceniami</FeatureListItem>
          ,<FeatureListItem>Kalendarz zleceń</FeatureListItem>,
          <FeatureListItem>Automatyczne raporty do zleceń</FeatureListItem>,
          <FeatureListItem>Ofertowanie i obsługa zamówień</FeatureListItem>,
          <FeatureListItem>Integracja z GUS</FeatureListItem>,
          <FeatureListItem>
            Panel klienta (dodawnie i akceptacja zleceń)
          </FeatureListItem>
          ,<FeatureListItem>Powiadomienia SMS i e-mail</FeatureListItem>,
          <FeatureListItem>
            Fakturowanie i płatności lub integracja z&nbsp;ERP
          </FeatureListItem>
          ,<FeatureListItem>Skrzynka pocztowa (IMAP)</FeatureListItem>,
        </FeatureList>
      </>,
    ],
    features: [
      "rwd",
      "aktualizacje",
      "braklimitow",
      "wsparcie",
      "dedykowane",
      "niestandardowe",
      "wiele",
      "wlasnepola",
      "cele",
      "wiazanieszans",
      "wymagalnosc",
      "utrata",
      "gardla",
      "lejek",
      "prognoza",
      "aktywnosc",
      "raportofert",
      "ofertowanie",
      "zamowienia",
      "fakturowanie",
      "szablony",
      "zlecenia",
      "kanban",
      "polazlecenie",
      "typy",
      "kalendarzzlecen",
      "raportzlecen",
      "ofertaproduktowa",
      "bazaklientow",
      "rabaty",
      "umowy",
      "segmentowanie",
      "opiekun",
      "gus",
      "wyszukiwanie",
      "uprawnienia",
      "role",
      "duplikaty",
      "kalendarze",
      "zadanianadzis",
      "zdarzenia",
      "poczta",
      "panelklienta",
      "sms",
    ],
  },
  dedicated: {
    name: "Dedicated",
    price: {
      onetime: {
        type: "custom",
        value: (
          <Text
            height="34px"
            display="block"
            textAlign="center"
            fontWeight="normal"
            fontSize="xl"
            color="white"
          >
            Napisz lub zadzwoń
          </Text>
        ),
      },
      year: {
        type: "custom",
        value: (
          <Text
            height="34px"
            display="block"
            textAlign="center"
            fontWeight="normal"
            fontSize="xl"
            color="white"
          >
            Napisz lub zadzwoń
          </Text>
        ),
      },
    },
    button: (
      <PricingButton to="/kontakt" border={0} w="full" boxShadow="sm">
        Kontakt
      </PricingButton>
    ),
    special: 2,
    isRecommended: false,
    bestFeatures: [
      <>
        <Link
          color="white"
          mt={0}
          textAlign="center"
          lineHeight="25px"
          display="block"
          marginBottom="40px"
          marginTop="20px"
          href="https://aurabusiness.pl/"
        >
          Upgrade do rozwiązania dedykowanego - <strong>Aura Business</strong>{" "}
        </Link>
        <FeatureList>
          <FeatureListItem>
            Indywidualne podejście do potrzeb biznesu
          </FeatureListItem>
          ,
          <FeatureListItem>
            Możliwość pełnego odwzorowania procesów biznesowych
          </FeatureListItem>{" "}
          ,<FeatureListItem>Szerokie możliwości integracji </FeatureListItem>,
          <FeatureListItem>Aplikacja mobilna</FeatureListItem>,
        </FeatureList>
      </>,
    ],
    features: [
      "rwd",
      "aktualizacje",
      "braklimitow",
      "wsparcie",
      "dedykowane",
      "niestandardowe",
      "wiele",
      "wlasnepola",
      "cele",
      "wiazanieszans",
      "wymagalnosc",
      "utrata",
      "gardla",
      "lejek",
      "prognoza",
      "aktywnosc",
      "raportofert",
      "ofertowanie",
      "zamowienia",
      "fakturowanie",
      "szablony",
      "zlecenia",
      "kanban",
      "polazlecenie",
      "typy",
      "kalendarzzlecen",
      "raportzlecen",
      "ofertaproduktowa",
      "bazaklientow",
      "rabaty",
      "umowy",
      "segmentowanie",
      "opiekun",
      "gus",
      "wyszukiwanie",
      "uprawnienia",
      "role",
      "duplikaty",
      "kalendarze",
      "zadanianadzis",
      "zdarzenia",
      "poczta",
      "panelklienta",
      "sms",
      "procesybiznesowe",
      "aplikacja",
    ],
  },
};

export default CennikZakup;
